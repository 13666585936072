@import url("reset.css");
@import url("normalize.css");
@import url("supporting.css");
@import url("jquery-ui.css");
@font-face {
  font-family: 'Georgia';
  src: local('Georgia'), local('Georgia'), url('/fonts/Georgia.woff2') format('woff2'), url('/fonts/Georgia.woff') format('woff');
}
@font-face {
  font-family: 'Georgia-Bold';
  src: local('Georgia-Bold'), local('Georgia-Bold'), url('/fonts/Georgia-Bold.woff2') format('woff2'), url('/fonts/Georgia-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-ExtraLight';
  src: local('Geometria-ExtraLight'), local('Geometria ExtraLight'), url('/fonts/Geometria-ExtraLight.woff2') format('woff2'), url('/fonts/Geometria-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-Light';
  src: local('Geometria-Light'), local('Geometria Light'), url('/fonts/Geometria-Light.woff2') format('woff2'), url('/fonts/Geometria-Light.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-Regular';
  src: local('Geometria-Regular'), local('Geometria Regular'), url('/fonts/Geometria-Regular.woff2') format('woff2'), url('/fonts/Geometria-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-Bold';
  src: local('Geometria-Bold'), local('Geometria Bold'), url('/fonts/Geometria-Bold.woff2') format('woff2'), url('/fonts/Geometria-Bold.woff') format('woff');
  font-weight: 700;
  font-style: bold;
}
body {
  font-size: 1em;
  font-family: 'Geometria-Regular';
  color: black;
}
body.no-scroll {
  overflow-y: hidden;
}
@media screen and (max-width: 1650px) {
  body {
    font-size: 0.85em;
  }
}
@media screen and (max-width: 1366px) {
  body {
    font-size: 0.7em;
  }
}
main {
  width: 84%;
  margin-left: 16%;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  main {
    width: 80%;
    margin-left: 20%;
  }
}
@media screen and (max-width: 900px) {
  main {
    width: 100%;
    margin-left: 0;
    padding-top: 5em;
  }
}
.ui-widget-header .ui-icon {
  background-image: url(/images/ui-icons_444444_256x240.png);
}
.button,
.button:focus,
.button:active {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 999px !important;
  color: white;
  background: #ffae02;
  padding: 1.3em 2em 1.5em 2em;
  font-size: 1.1em;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 0 0 0 #ffae02;
  transform: scale3d(1, 1, 1);
  font-family: 'Geometria-Regular' !important;
}
@media screen and (max-width: 1366px) {
  .button,
  .button:focus,
  .button:active {
    font-size: 1em;
  }
}
.button:hover,
.button:focus:hover,
.button:active:hover {
  box-shadow: 0 0 20px 0 #ffae02;
}
.button:active,
.button:focus:active,
.button:active:active {
  transform: scale3d(0.95, 0.95, 0.95);
}
/******************************************************** Styles ********************************************************/
.header {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: calc(16% + 1px);
  background-position: center;
  background-image: url(/images/pattern.jpg);
  color: white;
  z-index: 1;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 1024px) {
  .header {
    width: calc(20% + 1px);
  }
}
@media screen and (max-width: 900px) {
  .header {
    bottom: auto;
    right: 0;
    width: 100%;
    padding: 1.5em 6%;
  }
}
@media screen and (max-width: 600px) {
  .header {
    padding: 1em 6%;
  }
}
.header__logotype {
  display: flex;
  padding: 3.125em 0;
}
@media screen and (max-width: 1366px) {
  .header__logotype {
    padding: 2.5em 0;
  }
}
@media screen and (max-width: 900px) {
  .header__logotype {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
  }
}
.header__logotype img {
  margin: auto;
  width: 10.625em;
  height: 10.625em;
}
@media screen and (max-width: 1366px) {
  .header__logotype img {
    width: 7em;
    height: 7em;
  }
}
@media screen and (max-width: 900px) {
  .header__logotype img {
    width: 4em;
    height: 4em;
  }
}
@media screen and (max-width: 600px) {
  .header__logotype img {
    width: 3em;
    height: 3em;
  }
}
.header__button-mobile-menu {
  display: none;
  vertical-align: middle;
  height: 4em;
  width: 4em;
  position: absolute;
  right: 28.5em;
  top: 1.5em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/ic_menu.svg);
}
@media screen and (max-width: 900px) {
  .header__button-mobile-menu {
    display: inline-block;
  }
}
@media screen and (max-width: 600px) {
  .header__button-mobile-menu {
    height: 2.5em;
    width: 2.5em;
    left: calc((100% - 2.5em) / 2);
  }
}
@media screen and (max-width: 900px) {
  .header__menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #131313;
    background-image: none;
    text-align: center;
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
  }
}
.header__menu .menu__item {
  border-top: 1px solid #010103;
  position: relative;
  font-size: 1.5em;
  font-family: 'Geometria-ExtraLight';
  padding: 1em 1.5em;
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 1;
}
@media screen and (max-width: 1366px) {
  .header__menu .menu__item {
    font-size: 1.3em;
  }
}
.header__menu .menu__item:last-child {
  border-bottom: 1px solid #010103;
}
.header__menu .menu__item:last-child::after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  background: #2b2b2b;
}
@media screen and (max-width: 900px) {
  .header__menu .menu__item:last-child::after {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .header__menu .menu__item:last-child {
    border-bottom: none;
  }
}
.header__menu .menu__item::before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 1px;
  background: #2b2b2b;
  z-index: 10;
}
.header__menu .menu__item:hover {
  background: white;
  color: black;
}
.header__contacts {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 3em;
}
@media screen and (max-width: 900px) {
  .header__contacts {
    position: static;
    padding-bottom: 0;
  }
}
.header__contacts .contacts__phone {
  font-size: 1.5em;
  margin-bottom: 1.3em;
  font-family: 'Geometria-Bold';
}
@media screen and (max-width: 1366px) {
  .header__contacts .contacts__phone {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 900px) {
  .header__contacts .contacts__phone {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    font-size: 1em;
    top: 1.5em;
    right: 22em;
    margin-bottom: 0;
    height: 4em;
    width: 4em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/images/ic_phone.svg);
  }
}
@media screen and (max-width: 600px) {
  .header__contacts .contacts__phone {
    right: 6%;
    height: 3em;
    width: 3em;
  }
}
@media screen and (max-width: 900px) {
  .header__contacts .contacts__phone span {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .header__contacts .contacts__button {
    position: absolute;
    top: 1.5em;
    right: 6%;
  }
}
@media screen and (max-width: 600px) {
  .header__contacts .contacts__button {
    display: none;
  }
}
.promo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/1-img.jpg);
  padding: 9.375em 5% 15.375em 5%;
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  .promo {
    padding: 5.375em 5% 13.375em 5%;
  }
}
.promo .promo__content .promo__title {
  font-size: 6.6em;
  font-family: 'Georgia';
  font-weight: 600;
  transform: scale(1, 0.85);
}
@media screen and (max-width: 600px) {
  .promo .promo__content .promo__title {
    font-size: 4em;
  }
}
.promo .promo__content .promo__tagline {
  margin: 1em 0 3.5em 0;
}
@media screen and (max-width: 600px) {
  .promo .promo__content .promo__tagline {
    margin: 1em 0 2em 0;
  }
}
.promo .promo__content .promo__tagline .tagline__item {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.45em;
  font-family: 'Geometria-Light';
  line-height: 100%;
}
.promo .promo__content .promo__tagline .tagline__circle {
  display: inline-block;
  vertical-align: middle;
  margin: 3px 1em 0 1em;
  border-radius: 100%;
  height: 7px;
  width: 7px;
  background: white;
}
.promo .promo__content .promo__button {
  padding: 1.3em 4em 1.5em 4em;
}
.promo::before {
  content: '';
  position: absolute;
  left: -15px;
  bottom: -40px;
  right: -15px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url(/images/line.png);
  height: 80px;
}
.about {
  padding: 0 14%;
}
@media screen and (max-width: 1024px) {
  .about {
    padding: 0 6%;
  }
}
.about__information {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  top: -8em;
  background: white;
}
.about__information .information__images {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 55%;
}
@media screen and (max-width: 600px) {
  .about__information .information__images {
    width: 100%;
    height: 12em;
    position: static;
  }
}
.about__information .information__images .image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.about__information .information__images .image.image-1 {
  background-image: url(/images/2-img.jpg);
  height: 100%;
  display: inline-block;
  vertical-align: top;
  width: 40%;
  border: 10px solid white;
}
.about__information .information__images .image.image-2 {
  background-image: url(/images/3-img.jpg);
  height: 100%;
  display: inline-block;
  vertical-align: top;
  width: 60%;
  border: 10px solid white;
  border-left: none;
}
.about__information .information__content {
  padding: 4.35em 3em;
  width: 45%;
  margin-left: 55%;
}
@media screen and (max-width: 600px) {
  .about__information .information__content {
    width: 100%;
    margin-left: 0;
    padding: 2em 3em 3em 3em;
  }
}
.about__information .information__content .title {
  font-size: 4.5em;
  font-family: 'Georgia';
  margin-bottom: 0.2em;
  font-weight: 600;
  transform: scale(1, 0.85);
}
@media screen and (max-width: 600px) {
  .about__information .information__content .title {
    font-size: 3em;
  }
}
.about__information .information__content .description {
  line-height: 150%;
  font-family: 'Geometria-Regular';
}
.about__statistics {
  position: relative;
  top: -4em;
  padding-bottom: 2em;
}
@media screen and (max-width: 480px) {
  .about__statistics {
    padding-bottom: 0;
  }
}
.about__statistics .statistics__item {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 25%;
  border-right: 1px solid #d8d8d8;
  padding: 2em;
}
@media screen and (max-width: 480px) {
  .about__statistics .statistics__item {
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  .about__statistics .statistics__item:nth-child(1) {
    border-bottom: 1px solid #d8d8d8;
  }
}
@media screen and (max-width: 480px) {
  .about__statistics .statistics__item:nth-child(2) {
    border-right: none;
    border-bottom: 1px solid #d8d8d8;
  }
}
@media screen and (max-width: 480px) {
  .about__statistics .statistics__item:nth-child(4) {
    border-right: none;
  }
}
.about__statistics .statistics__item .number {
  font-size: 2.5em;
  font-family: 'Georgia';
  margin-bottom: 0.3em;
}
.about__statistics .statistics__item .title {
  line-height: 150%;
}
.menu {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/4-img.jpg);
  color: white;
  text-align: center;
  padding: 12em 5%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
@media screen and (max-width: 1024px) {
  .menu {
    padding: 12em 6%;
  }
}
@media screen and (max-width: 600px) {
  .menu {
    padding: 4em 6% 9em 6%;
  }
}
.menu__content {
  width: 80%;
  margin: auto;
}
@media screen and (max-width: 1024px) {
  .menu__content {
    width: 100%;
  }
}
.menu__content .menu__title {
  font-size: 4.5em;
  font-family: 'Inter', sans-serif;
  transform: scale(1, 0.85);
  margin-bottom: 70px;
  font-weight: 800;
  line-height: 102px;
  color: #000000;
}
@media screen and (max-width: 600px) {
  .menu__content .menu__title {
    font-size: 3em;
  }
}
.menu__content .menu__description {
  padding: 0 22%;
  line-height: 160%;
  margin: 3.2em 0;
  font-family: 'Geometria-Light';
}
@media screen and (max-width: 1024px) {
  .menu__content .menu__description {
    padding: 0 12%;
    margin: 2em 0 3.2em 0;
  }
}
@media screen and (max-width: 600px) {
  .menu__content .menu__description {
    padding: 0;
  }
}
.menu__content .menu__buttons .button {
  width: 18%;
}
@media screen and (max-width: 1024px) {
  .menu__content .menu__buttons .button {
    width: 22%;
  }
}
@media screen and (max-width: 768px) {
  .menu__content .menu__buttons .button {
    width: 25%;
  }
}
@media screen and (max-width: 600px) {
  .menu__content .menu__buttons .button {
    width: 51%;
  }
}
.menu__content .menu__buttons .button:first-child {
  margin-right: 4em;
}
@media screen and (max-width: 600px) {
  .menu__content .menu__buttons .button:first-child {
    margin-right: 0;
    margin-bottom: 2em;
  }
}
.menu__content-header {
  width: 100%;
  display: flex;
}
.menu__content-header-border {
  display: inline-block;
  width: 50%;
  border-bottom: 5px solid white;
}
.menu__content-header-border_left {
  margin-right: 2em;
}
.menu__content-header-border_right {
  margin-left: 2em;
}
@media screen and (max-width: 450px) {
  .menu__content-header-border {
    border-bottom: 2.5px solid white;
  }
}
.menu__content-header-title {
  display: inline-block;
  font-size: 4.5em;
  font-family: 'Georgia-Bold';
  transform: translateY(45%);
  line-height: 70%;
}
.menu__content-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-left: 5px solid white;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  padding-top: 4.5em;
  padding-bottom: 2.5em;
}
@media screen and (max-width: 450px) {
  .menu__content-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    border-left: 2.5px solid white;
    border-bottom: 2.5px solid white;
    border-right: 2.5px solid white;
    height: 33em;
    padding-bottom: 0;
  }
}
.menu__button {
  display: inline-block;
  width: 25%;
}
@media screen and (max-width: 450px) {
  .menu__button {
    width: 50%;
  }
}
.menu__button-icon-substrate {
  width: 130px;
  height: 130px;
  margin: auto;
  background-color: white;
  border-radius: 0.8em;
}
.menu__button-icon-substrate:hover {
  background-color: #FBAC3C;
}
@media screen and (max-width: 450px) {
  .menu__button-icon-substrate {
    width: 73px;
    height: 73px;
  }
}
.menu__button-icon {
  width: 100%;
  height: 100%;
}
.menu__button-icon_main {
  background-size: 51px 75px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/main-menu.svg);
}
.menu__button-icon_main:hover {
  background-image: url(/images/main-menu-hover.svg);
}
@media screen and (max-width: 450px) {
  .menu__button-icon_main {
    background-size: 27px 39px;
  }
}
.menu__button-icon_pan-asian {
  background-size: 70px 79px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/pan-asian-menu.svg);
}
.menu__button-icon_pan-asian:hover {
  background-image: url(/images/pan-asian-menu-hover.svg);
}
@media screen and (max-width: 450px) {
  .menu__button-icon_pan-asian {
    background-size: 37px 42px;
  }
}
.menu__button-icon_bar {
  background-size: 65px 77px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/bar-menu.svg);
}
.menu__button-icon_bar:hover {
  background-image: url(/images/bar-menu-hover.svg);
}
@media screen and (max-width: 450px) {
  .menu__button-icon_bar {
    background-size: 35px 41px;
  }
}
.menu__button-icon_children {
  background-size: 86px 77px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/children-menu.svg);
}
.menu__button-icon_children:hover {
  background-image: url(/images/children-menu-hover.svg);
}
@media screen and (max-width: 450px) {
  .menu__button-icon_children {
    background-size: 46px 41px;
  }
}
.menu__button-title {
  font-size: 2.2em;
  font-family: 'Geometria-Regular';
  margin-top: 0.7em;
}
@media screen and (max-width: 450px) {
  .menu__button-title {
    font-size: 1.6em;
  }
}
.menu::before {
  content: '';
  position: absolute;
  left: -35px;
  bottom: -50px;
  right: -35px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url(/images/line.png);
  height: 80px;
}
.bonus-card {
  padding: 4em 14% 0 14%;
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 1024px) {
  .bonus-card {
    padding: 4em 6% 0 6%;
  }
}
@media screen and (max-width: 600px) {
  .bonus-card {
    padding: 4em 6%;
  }
}
.bonus-card__content {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  background-size: 32%;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-image: url(/images/background.jpg);
  background-color: white;
  padding: 4.5em;
  position: relative;
  top: -10em;
}
@media screen and (max-width: 600px) {
  .bonus-card__content {
    padding: 2.5em 3em;
    top: 0;
  }
}
.bonus-card__content .bonus-card__text {
  width: 55%;
  display: inline-block;
  vertical-align: middle;
}
@media screen and (max-width: 1024px) {
  .bonus-card__content .bonus-card__text {
    width: 100%;
  }
}
.bonus-card__content .bonus-card__text .bonus-card__title {
  font-size: 4.5em;
  font-family: 'Georgia';
  line-height: 100%;
  margin-bottom: 0.4em;
  font-weight: 600;
  transform: scale(1, 0.85);
}
@media screen and (max-width: 600px) {
  .bonus-card__content .bonus-card__text .bonus-card__title {
    font-size: 3em;
  }
}
.bonus-card__content .bonus-card__text .bonus-card__description {
  line-height: 160%;
  margin-bottom: 1em;
}
.bonus-card__content .bonus-card__text .bonus-card__description:last-child {
  margin-bottom: 0;
}
.bonus-card__content .bonus-card__cards {
  width: 46%;
  margin-left: 5%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  top: 7em;
  bottom: 0;
  right: -4.5em;
}
@media screen and (max-width: 1024px) {
  .bonus-card__content .bonus-card__cards {
    width: 100%;
    position: static;
    margin-left: 0;
    margin-top: 3em;
  }
}
@media screen and (max-width: 600px) {
  .bonus-card__content .bonus-card__cards {
    margin-top: 1em;
  }
}
.bonus-card__content .bonus-card__cards .cards__image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/cards.png);
  height: 25em;
}
@media screen and (max-width: 600px) {
  .bonus-card__content .bonus-card__cards .cards__image {
    height: 20em;
  }
}
.bonus-card__content .bonus-card__cards .cards__button {
  padding: 1.3em 4em 1.5em 4em;
}
.bonus-card::before {
  content: '';
  position: absolute;
  left: -35px;
  bottom: -40px;
  right: -35px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url(/images/line.png);
  height: 80px;
}
.advantages {
  padding: 8em 5%;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .advantages {
    padding: 8em 6%;
  }
}
@media screen and (max-width: 600px) {
  .advantages {
    padding: 4em 6%;
  }
}
.advantages__title {
  color: white;
  font-size: 4.5em;
  font-family: 'Georgia';
  position: relative;
  z-index: 1;
  margin-bottom: 1em;
  font-weight: 600;
  transform: scale(1, 0.85);
}
@media screen and (max-width: 1024px) {
  .advantages__title {
    margin-bottom: 1.5em;
  }
}
@media screen and (max-width: 600px) {
  .advantages__title {
    font-size: 3em;
    margin-bottom: 2.5em;
  }
}
.advantages__content {
  position: relative;
  z-index: 1;
}
.advantages__content .advantages__item {
  height: 23.125em;
  width: calc((100% - 5.625em) / 4);
  display: inline-block;
  vertical-align: top;
  margin-right: 1.875em;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  padding: 1.75em;
  overflow: hidden;
  position: relative;
  cursor: default;
}
@media screen and (max-width: 1024px) {
  .advantages__content .advantages__item {
    padding: 5.75em 1.75em 1.75em 1.75em;
    overflow: visible;
  }
}
@media screen and (max-width: 900px) {
  .advantages__content .advantages__item {
    width: calc((100% - 3.75em) / 2);
    height: 19em;
  }
}
@media screen and (max-width: 600px) {
  .advantages__content .advantages__item {
    width: 100%;
    height: 16em;
  }
}
.advantages__content .advantages__item:last-child {
  margin-right: 0;
}
@media screen and (max-width: 900px) {
  .advantages__content .advantages__item:nth-child(even) {
    margin-right: 0;
  }
}
@media screen and (max-width: 900px) {
  .advantages__content .advantages__item:nth-child(1),
  .advantages__content .advantages__item:nth-child(2) {
    margin-bottom: 6em;
  }
}
@media screen and (max-width: 600px) {
  .advantages__content .advantages__item:nth-child(3) {
    margin-bottom: 6em;
  }
}
.advantages__content .advantages__item .title {
  font-size: 1.6em;
  font-family: 'Georgia';
  margin-bottom: 0.75em;
  position: relative;
  z-index: 1;
  font-weight: 600;
  transform: scale(1, 0.85);
}
.advantages__content .advantages__item .description {
  position: absolute;
  left: 0;
  top: 7em;
  right: 0;
  padding: 0 2em;
  line-height: 160%;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: all 0.3s ease;
}
@media screen and (max-width: 1024px) {
  .advantages__content .advantages__item .description {
    font-size: 0.8em;
    top: 9em;
    opacity: 1;
    visibility: visible;
    top: 12.5em;
  }
}
.advantages__content .advantages__item .icon {
  width: 13.5em;
  height: 13.5em;
  background: #ffae02;
  border-radius: 100%;
  margin: auto;
  position: relative;
  z-index: 0;
  transition: all 0.7s ease;
}
@media screen and (max-width: 1200px) {
  .advantages__content .advantages__item .icon {
    width: 10.5em;
    height: 10.5em;
  }
}
@media screen and (max-width: 1024px) {
  .advantages__content .advantages__item .icon {
    position: absolute;
    left: 0;
    top: -5em;
    right: 0;
    background: none;
    width: auto;
    text-align: center;
  }
}
.advantages__content .advantages__item .icon i {
  transition: all 0.3s ease;
  opacity: 1;
  display: block;
  position: absolute;
  left: 2.5em;
  top: 2.5em;
  right: 2.5em;
  bottom: 2.5em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (max-width: 1024px) {
  .advantages__content .advantages__item .icon i {
    display: inline-block;
    width: 10.5em;
    height: 10.5em;
    background-color: #ffae02;
    margin: auto;
    border-radius: 999px;
    background-size: 60%;
    position: static;
  }
}
@media screen and (max-width: 600px) {
  .advantages__content .advantages__item .icon i {
    left: 1.5em;
    top: 1.5em;
    right: 1.5em;
    bottom: 1.5em;
  }
}
.advantages__content .advantages__item:hover .icon {
  transform: translate3d(0, -210px, 0);
}
@media screen and (max-width: 1366px) {
  .advantages__content .advantages__item:hover .icon {
    transform: translate3d(0, -175px, 0);
  }
}
.advantages__content .advantages__item:hover .icon i {
  opacity: 0;
}
.advantages__content .advantages__item:hover .description {
  opacity: 1;
  visibility: visible;
}
.advantages__background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 70%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/images/5-img.jpg);
  z-index: 0;
}
@media screen and (max-width: 900px) {
  .advantages__background {
    height: 80%;
  }
}
@media screen and (max-width: 600px) {
  .advantages__background {
    height: 100%;
  }
}
.reservation {
  text-align: center;
  padding: 0 5% 8em 5%;
}
@media screen and (max-width: 1024px) {
  .reservation {
    padding: 0 6% 8em 6%;
  }
}
@media screen and (max-width: 600px) {
  .reservation {
    padding: 4em 6%;
  }
}
.reservation__title {
  font-size: 4.5em;
  font-family: 'Georgia';
  margin-bottom: 0.75em;
  font-weight: 600;
  transform: scale(1, 0.85);
}
@media screen and (max-width: 600px) {
  .reservation__title {
    font-size: 3em;
  }
}
.reservation__content .reservation__form {
  width: 58%;
  margin: auto;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .reservation__content .reservation__form {
    width: 80%;
  }
}
@media screen and (max-width: 1024px) {
  .reservation__content .reservation__form {
    width: 100%;
  }
}
.reservation__content .reservation__form .form__input {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  text-align: left;
  margin-bottom: 4em;
  border-bottom: 1px solid #d8d8d8;
  position: relative;
}
@media screen and (max-width: 600px) {
  .reservation__content .reservation__form .form__input {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 3em;
  }
}
.reservation__content .reservation__form .form__input.date {
  margin-right: 4%;
  width: 22%;
}
.reservation__content .reservation__form .form__input.time {
  margin-right: 4%;
  width: 22%;
}
.reservation__content .reservation__form .form__input.name {
  margin-right: 4%;
}
.reservation__content .reservation__form .form__input input {
  width: 100%;
  font-size: 1em;
  font-family: 'Geometria-Light';
  padding: 0 0 1em 0;
  background: none;
  position: relative;
  z-index: 1;
}
.reservation__content .reservation__form .form__input span {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  color: #a6a6a6;
  transition: all 0.3s ease;
}
.reservation__content .reservation__form .form__input span.active {
  transform: translate3d(0, -32px, 0);
}
.reservation__content .reservation__form .form__data-processing {
  margin-top: 1em;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .reservation__content .reservation__form .form__data-processing {
    margin-top: 0;
    margin-bottom: 2em;
  }
}
.reservation__content .reservation__form .form__data-processing input {
  background: #f7f7f7;
  appearance: none;
  height: 1.8em;
  width: 1.8em;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #ececec;
  position: relative;
}
.reservation__content .reservation__form .form__data-processing input::before {
  content: '';
  position: absolute;
  left: 7px;
  top: 7px;
  right: 7px;
  bottom: 7px;
  background: #ffae02;
  transform: scale3d(0, 0, 0);
  transition: all 0.2s ease;
}
@media screen and (max-width: 600px) {
  .reservation__content .reservation__form .form__data-processing input::before {
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
  }
}
.reservation__content .reservation__form .form__data-processing input:checked::before {
  transform: scale3d(1, 1, 1);
}
.reservation__content .reservation__form .form__data-processing span {
  color: #a6a6a6;
  margin-left: 1em;
  margin-top: 2px;
  display: inline-block;
  vertical-align: middle;
}
.reservation__content .reservation__form .form__button {
  position: absolute;
  bottom: -1.5em;
  right: 0;
  padding: 1.3em 5em 1.5em 5em;
}
@media screen and (max-width: 600px) {
  .reservation__content .reservation__form .form__button {
    position: static;
  }
}
.teaser {
  position: relative;
  padding: 10px 43px 40px 43px;
  border-radius: 20px;
  font-family: 'Inter', sans-serif;
  background: #FFAE02;
}
@media screen and (max-width: 768px) {
  .teaser {
    padding: 5.729vw 4.688vw;
  }
}
.teaser__row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  padding: 1.042vw 1.302vw;
  border-radius: 1.042vw;
  background: #D29106;
}
@media (min-width: 1920px) {
  .teaser__row {
    padding: 20px 25px;
    border-radius: 20px;
  }
}
@media screen and (max-width: 768px) {
  .teaser__row {
    flex-direction: column;
    padding: 3.906vw 6.510vw;
    z-index: 2;
    border-radius: 2.604vw;
  }
}
.teaser__title {
  margin-bottom: 8.594vw;
  font-size: 3.385vw;
  line-height: 5.313vw;
  color: #000;
  font-weight: 700;
}
@media (min-width: 1920px) {
  .teaser__title {
    margin-bottom: 165px;
    line-height: 102px;
    font-size: 65px;
  }
}
@media screen and (max-width: 1024px) {
  .teaser__title {
    margin-bottom: 10.789vw;
  }
}
@media screen and (max-width: 900px) {
  .teaser__title {
    font-size: 4.385vw;
    margin-bottom: 11.444vw;
  }
}
@media screen and (max-width: 768px) {
  .teaser__title {
    font-size: 6.771vw;
    margin-bottom: 52.444vw;
  }
}
.teaser__text {
  flex: 0 1 35%;
  font-weight: 700;
  font-size: 1.042vw;
  line-height: 1.354vw;
  text-align: left;
  color: #fff;
}
@media (min-width: 1920px) {
  .teaser__text {
    font-size: 20px;
    line-height: 26px;
  }
}
@media screen and (max-width: 900px) {
  .teaser__text {
    font-size: 1.556vw;
    line-height: 2.111vw;
  }
}
@media screen and (max-width: 768px) {
  .teaser__text {
    flex: 0 1 100%;
    margin-bottom: 4.688vw;
    font-size: 2.995vw;
    line-height: 3.776vw;
    text-align: center;
  }
}
.teaser__button-ico {
  margin-right: 0.260vw;
}
@media (min-width: 1920px) {
  .teaser__button-ico {
    margin-right: 5px;
  }
}
@media screen and (max-width: 900px) {
  .teaser__button-ico {
    margin-right: 0.556vw;
  }
}
@media screen and (max-width: 768px) {
  .teaser__button-ico {
    margin-right: 1.823vw;
  }
}
.teaser__button-ico img {
  width: 1.354vw;
}
@media (min-width: 1920px) {
  .teaser__button-ico img {
    width: 26px;
  }
}
@media screen and (max-width: 900px) {
  .teaser__button-ico img {
    width: 2.222vw;
  }
}
@media screen and (max-width: 768px) {
  .teaser__button-ico img {
    width: 3.906vw;
  }
}
.teaser__button-text {
  color: #D29106;
}
@media screen and (max-width: 768px) {
  .teaser__button-text {
    font-size: 2.344vw;
  }
}
.teaser__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 35%;
}
@media screen and (max-width: 768px) {
  .teaser__buttons {
    flex: 0 1 100%;
    justify-content: center;
  }
}
.teaser__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 7.813vw;
  height: 2.344vw;
  padding: 0.208vw 0.781vw;
  margin-right: 0.521vw;
  background: #fff;
  color: #D29106;
  border-radius: 0.521vw;
  font-weight: 600;
  cursor: pointer;
  transition: all 200ms ease;
}
@media (min-width: 1920px) {
  .teaser__button {
    min-width: 150px;
    height: 45px;
    padding: 4px 15px;
    margin-right: 10px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 900px) {
  .teaser__button {
    padding: 0.778vw 1.333vw;
    border-radius: 1.111vw;
    min-width: 13.889vw;
    margin-right: 1.111vw;
    height: 4.222vw;
    font-size: 1.444vw;
  }
}
@media screen and (max-width: 768px) {
  .teaser__button {
    padding: 0.521vw 3.125vw;
    border-radius: 1.302vw;
    min-width: 24.740vw;
    margin-right: 1.953vw;
    height: 7.813vw;
  }
}
.teaser__button:hover {
  opacity: 0.8;
}
.teaser__button:last-child {
  margin: 0;
}
.teaser__qr-link {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 20%;
  z-index: 2;
}
@media (min-width: 1920px) {
  .teaser__qr-link {
    bottom: 40px;
  }
}
@media screen and (max-width: 900px) {
  .teaser__qr-link {
    width: 18%;
  }
}
@media screen and (max-width: 768px) {
  .teaser__qr-link {
    bottom: 30.208vw;
    width: 41.667vw;
    z-index: 0;
  }
}
.teaser__qr {
  display: block;
  max-width: 100%;
}
.contacts {
  padding: 7.2em 5%;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .contacts {
    padding: 4em 6% 35em 6%;
  }
}
.contacts__content {
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 1;
  background: white;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  padding: 4.5em 5.5em;
  width: 60%;
}
@media screen and (max-width: 1650px) {
  .contacts__content {
    width: 67%;
  }
}
@media screen and (max-width: 1024px) {
  .contacts__content {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .contacts__content {
    padding: 3em;
  }
}
.contacts__content .contacts__title {
  font-size: 4.5em;
  font-family: 'Georgia';
  margin-bottom: 0.25em;
  font-weight: 600;
  transform: scale(1, 0.85);
}
@media screen and (max-width: 600px) {
  .contacts__content .contacts__title {
    font-size: 3em;
  }
}
.contacts__content .contacts__description {
  line-height: 160%;
  margin-bottom: 2em;
}
@media screen and (max-width: 600px) {
  .contacts__content .contacts__description {
    margin-bottom: 1em;
  }
}
.contacts__content .contacts__item {
  margin: 0 0 2em 0;
  display: inline-block;
  vertical-align: top;
  width: 60%;
}
@media screen and (max-width: 1366px) {
  .contacts__content .contacts__item {
    width: 100% !important;
  }
}
@media screen and (max-width: 600px) {
  .contacts__content .contacts__item {
    margin: 1em 0;
  }
}
.contacts__content .contacts__item.margin-right {
  margin-right: 10%;
}
.contacts__content .contacts__item .title {
  color: #a6a6a6;
  margin-bottom: 0.7em;
}
.contacts__content .contacts__item .text {
  font-size: 1.2em;
  line-height: 160%;
}
.contacts__content .contacts__social {
  display: inline-block;
  vertical-align: top;
  margin-right: 12%;
}
@media screen and (max-width: 1650px) {
  .contacts__content .contacts__social {
    margin-right: 8%;
  }
}
@media screen and (max-width: 600px) {
  .contacts__content .contacts__social {
    margin-right: 0;
    margin-bottom: 2em;
    width: 100%;
  }
}
.contacts__content .contacts__social .social__item {
  display: inline-block;
  vertical-align: middle;
  height: 4.2em;
  width: 4.2em;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  border: 3px solid #ffae02;
  margin-right: 0.8em;
  cursor: pointer;
  transition: all 0.3s ease;
}
.contacts__content .contacts__social .social__item.in {
  background-image: url(/images/insta.svg);
}
.contacts__content .contacts__social .social__item.in:hover {
  background-image: url(/images/insta-white.svg);
}
.contacts__content .contacts__social .social__item.vk {
  background-image: url(/images/vk.svg);
}
.contacts__content .contacts__social .social__item.vk:hover {
  background-image: url(/images/vk-white.svg);
}
.contacts__content .contacts__social .social__item.trip {
  background-image: url(/images/tripadvisor.svg);
}
.contacts__content .contacts__social .social__item.trip:hover {
  background-image: url(/images/tripadvisor-white.svg);
}
.contacts__content .contacts__social .social__item:hover {
  background-color: #ffae02;
}
.contacts__content .contacts__button {
  padding: 1.3em 4em 1.5em 4em;
  color: white !important;
}
@media screen and (max-width: 1366px) {
  .contacts__content .contacts__button {
    padding: 1.3em 2.5em 1.5em 2.5em;
  }
}
@media screen and (max-width: 1200px) {
  .contacts__content .contacts__button {
    margin-top: 2em;
  }
}
.contacts__map {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.modal-window {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  padding: 3em;
}
.modal-window.open {
  opacity: 1;
  visibility: visible;
}
.modal-window__content {
  padding: 3em 0;
  position: relative;
  z-index: 1;
  position: absolute;
  left: 30%;
  top: 0;
  right: 30%;
  bottom: 0;
  overflow-x: hidden;
}
.modal-window__content .holder {
  position: absolute;
  left: 0;
  top: 0;
  right: -17px;
  bottom: 0;
  padding-right: 17px;
  overflow-y: scroll;
}
@media screen and (max-width: 768px) {
  .modal-window__content .holder {
    padding-right: 0;
    right: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (max-width: 1024px) {
  .modal-window__content {
    left: 25%;
    right: 25%;
  }
}
@media screen and (max-width: 768px) {
  .modal-window__content {
    left: 20%;
    right: 20%;
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (max-width: 768px) {
  .modal-window__content {
    left: 0;
    right: 0;
    padding: 0;
  }
}
.modal-window__content.element::-webkit-scrollbar {
  width: 0;
}
.modal-window__content.element {
  -ms-overflow-style: none;
}
.modal-window__content.element {
  overflow: -moz-scrollbars-none;
}
.modal-window__content .modal-window__body {
  background: white;
  padding: 3em 5em;
  position: relative;
}
@media screen and (max-width: 600px) {
  .modal-window__content .modal-window__body {
    padding: 4em 3em;
  }
}
.modal-window__content .modal-window__body .modal-window__button-close {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  height: 2em;
  width: 2em;
  background-image: url(/images/close.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.modal-window__content .modal-window__body .modal-window__logotype {
  height: 10em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/images/logotype-black.png);
  margin-bottom: 1.5em;
}
.modal-window__content .modal-window__body .modal-window__title {
  font-size: 1.6em;
  text-align: center;
  font-family: 'Geometria-Bold';
  text-transform: uppercase;
  margin-bottom: 1em;
}
.modal-window__content .modal-window__body .modal-window__form {
  text-align: center;
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__input {
  position: relative;
  margin-bottom: 2em;
  text-align: left;
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__input input {
  width: 100%;
  padding: 1.1em 0;
  font-family: 'Geometria-Regular';
  font-size: 1.2em;
  border-bottom: 3px solid #e9e9e9;
  transition: all 0.2s ease;
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__input input.focus {
  border-bottom: 3px solid #a6a6a6;
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__input input.orange {
  border-bottom: 3px solid #ffae02 !important;
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__input .line {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 3px;
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__ckeckbox {
  margin-bottom: 2em;
  text-align: left;
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__ckeckbox p {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2em;
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__ckeckbox > p {
  width: 45%;
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__ckeckbox input {
  background: #f7f7f7;
  appearance: none;
  height: 1.8em;
  width: 1.8em;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #ececec;
  position: relative;
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__ckeckbox input::before {
  content: '';
  position: absolute;
  left: 7px;
  top: 7px;
  right: 7px;
  bottom: 7px;
  background: #ffae02;
  transform: scale3d(0, 0, 0);
  transition: all 0.2s ease;
}
@media screen and (max-width: 600px) {
  .modal-window__content .modal-window__body .modal-window__form .modal-window__ckeckbox input::before {
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
  }
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__ckeckbox input:checked::before {
  transform: scale3d(1, 1, 1);
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__ckeckbox .ckeckbox__item {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1em;
}
.modal-window__content .modal-window__body .modal-window__form .data-processing {
  position: relative;
}
.modal-window__content .modal-window__body .modal-window__form .data-processing.ckeckbox__item {
  margin-left: 0;
}
.modal-window__content .modal-window__body .modal-window__form .data-processing input {
  position: absolute;
  top: 0;
  left: 0;
}
.modal-window__content .modal-window__body .modal-window__form .data-processing p {
  margin-left: 2em;
}
.modal-window__content .modal-window__body .modal-window__form .modal-window__button {
  padding: 1.3em 4em 1.5em 4em;
}
.modal-window__blackout {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.8);
}
#smartbanner {
  position: relative !important;
  z-index: 99999 !important;
  top: 0 !important;
  position: absolute !important;
  left: 0;
  top: -82px;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  height: 78px;
  font-family: 'Helvetica Neue', sans-serif;
  background: -webkit-linear-gradient(top, #f4f4f4 0%, #cdcdcd 100%);
  background-image: -ms-linear-gradient(top, #F4F4F4 0%, #CDCDCD 100%);
  background-image: -moz-linear-gradient(top, #F4F4F4 0%, #CDCDCD 100%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
  user-select: none;
}
#smartbanner.no-icon .sb-icon {
  display: none;
}
#smartbanner.windows .sb-icon {
  border-radius: 0;
}
#smartbanner.android {
  border-color: #212228;
  background: #3d3d3d url('data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7');
  border-top: 5px solid #88B131;
  box-shadow: none;
}
#smartbanner.android .sb-close {
  border: 0;
  width: 17px;
  height: 17px;
  line-height: 17px;
  color: #b1b1b3;
  background: #1c1e21;
  text-shadow: 0 1px 1px #000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3);
}
#smartbanner.android .sb-close:active {
  color: #eee;
}
#smartbanner.android .sb-info {
  color: #ccc;
  text-shadow: 0 1px 2px #000;
}
#smartbanner.android .sb-info strong {
  color: #fff;
}
#smartbanner.android .sb-button {
  min-width: 12%;
  border: none;
  padding: 1px;
  color: #d1d1d1;
  background: none;
  border-radius: 0;
  box-shadow: none;
  min-height: 28px;
}
#smartbanner.android .sb-button span {
  text-align: center;
  display: block;
  padding: 5px 10px;
  background-color: #007930;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#007930), to(#007930));
  background-image: -moz-linear-gradient(top, #007930, #007930);
  text-transform: none;
  text-shadow: none;
  box-shadow: none;
}
#smartbanner.android .sb-button:active,
#smartbanner.android .sb-button:hover {
  background: none;
}
#smartbanner.android .sb-button:active span,
#smartbanner.android .sb-button:hover span {
  background: #2AC7E1;
}
#smartbanner .sb-container {
  margin: 0 auto;
}
#smartbanner .sb-close {
  position: absolute;
  left: 5px;
  top: 5px;
  display: block;
  width: 14px;
  height: 14px;
  font-family: 'ArialRoundedMTBold', Arial;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  background: #070707;
  text-decoration: none;
  text-shadow: none;
  border-radius: 14px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: subpixel-antialiased;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 6px 6px;
  background-image: url(../images/close-white.svg);
}
#smartbanner .sb-close:active {
  font-size: 13px;
  color: #aaa;
}
#smartbanner .sb-icon {
  position: absolute;
  left: 30px;
  top: 10px;
  display: block;
  width: 57px;
  height: 57px;
  background: rgba(0, 0, 0, 0.6);
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
#smartbanner .sb-icon.gloss:after {
  content: '';
  position: absolute;
  left: 0;
  top: -1px;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 50%;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.2) 100%);
  border-radius: 10px 10px 12px 12px;
}
#smartbanner .sb-info {
  position: absolute;
  left: 98px;
  top: 18px;
  width: 44%;
  font-size: 11px;
  line-height: 1.2em;
  font-weight: bold;
  color: #6a6a6a;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}
#smartbanner .sb-info strong {
  display: block;
  font-size: 13px;
  color: #4d4d4d;
  line-height: 18px;
}
#smartbanner .sb-info > span {
  display: block;
}
#smartbanner .sb-info em {
  font-style: normal;
  text-transform: uppercase;
}
#smartbanner .sb-button {
  position: absolute;
  right: 20px;
  top: 24px;
  border: 1px solid #bfbfbf;
  padding: 0 10px;
  min-width: 10%;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  color: #6a6a6a;
  background: -webkit-linear-gradient(top, #efefef 0%, #dcdcdc 100%);
  text-transform: uppercase;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6), 0 1px 0 rgba(255, 255, 255, 0.7) inset;
}
#smartbanner .sb-button:hover,
#smartbanner .sb-button:active {
  background: -webkit-linear-gradient(top, #dcdcdc 0%, #efefef 100%);
}
#smartbanner,
html.sb-animation {
  -webkit-transition: all .3s ease;
}
#smartbanner #smartbanner.no-icon .sb-info {
  left: 34px;
}
.smartbanner_android,
.smartbanner_ios {
  display: none;
}
