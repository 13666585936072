@import url("reset.css"); // Resetting Styles
@import url("normalize.css"); // Normalizing Styles
@import url("supporting.css"); // Supporting Classes
@import url("jquery-ui.css");


// Connecting fonts
@font-face {
	font-family: 'Georgia';
	src: local('Georgia'), local('Georgia'),
         url('/fonts/Georgia.woff2') format('woff2'), url('/fonts/Georgia.woff') format('woff');
}

@font-face {
	font-family: 'Georgia-Bold';
	src: local('Georgia-Bold'), local('Georgia-Bold'),
	url('/fonts/Georgia-Bold.woff2') format('woff2'), url('/fonts/Georgia-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Geometria-ExtraLight';
	src: local('Geometria-ExtraLight'), local('Geometria ExtraLight'),
         url('/fonts/Geometria-ExtraLight.woff2') format('woff2'), url('/fonts/Geometria-ExtraLight.woff') format('woff');
}

@font-face {
	font-family: 'Geometria-Light';
	src: local('Geometria-Light'), local('Geometria Light'),
         url('/fonts/Geometria-Light.woff2') format('woff2'), url('/fonts/Geometria-Light.woff') format('woff');
}

@font-face {
	font-family: 'Geometria-Regular';
	src: local('Geometria-Regular'), local('Geometria Regular'),
         url('/fonts/Geometria-Regular.woff2') format('woff2'), url('/fonts/Geometria-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Geometria-Bold';
	src: local('Geometria-Bold'), local('Geometria Bold'),
         url('/fonts/Geometria-Bold.woff2') format('woff2'), url('/fonts/Geometria-Bold.woff') format('woff');
	    font-weight: 700;
	    font-style: bold;
}


@font-georgia: 'Georgia';
@font-georgia-bold: 'Georgia-Bold';
@font-geometria-extraLight: 'Geometria-ExtraLight';
@font-geometria-light: 'Geometria-Light';
@font-geometria-regular: 'Geometria-Regular';
@font-geometria-bold: 'Geometria-Bold';

@color-orange: #ffae02;
@color-grey: #a6a6a6;

@1750: ~"screen and (max-width: 1750px)";
@1650: ~"screen and (max-width: 1650px)";
@1400: ~"screen and (max-width: 1400px)";
@1366: ~"screen and (max-width: 1366px)";
@1300: ~"screen and (max-width: 1300px)";
@1250: ~"screen and (max-width: 1250px)";
@1200: ~"screen and (max-width: 1200px)";
@1024: ~"screen and (max-width: 1024px)";
@900: ~"screen and (max-width: 900px)";
@768: ~"screen and (max-width: 768px)";
@700: ~"screen and (max-width: 700px)";
@600: ~"screen and (max-width: 600px)";
@480: ~"screen and (max-width: 480px)";
@450: ~"screen and (max-width: 450px)";
@400: ~"screen and (max-width: 400px)";
@350: ~"screen and (max-width: 350px)";

body{
	font-size: 1em;
	font-family: @font-geometria-regular;
	color: black;

	&.no-scroll{
		overflow-y: hidden;
	}
	@media @1650{
		font-size: 0.85em;
	}
	@media @1366{
		font-size: 0.7em;
	}
}

main{
	width: 84%;
	margin-left: 16%;
	position: relative;
	z-index: 0;
	overflow: hidden;
	@media @1024{
		width: 80%;
		margin-left: 20%;
	}
	@media @900{
		width: 100%;
		margin-left: 0;
		padding-top: 5em;
	}
}

.ui-widget-header .ui-icon {
	background-image: url(/images/ui-icons_444444_256x240.png);
}

.button, .button:focus, .button:active{
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	border-radius: 999px !important;
	color: white;
	background: @color-orange;
	padding: 1.3em 2em 1.5em 2em;
	font-size: 1.1em;
	text-align: center;
	transition: all 0.3s ease;
	box-shadow: 0 0 0 0 @color-orange;
	transform: scale3d(1, 1, 1);
	font-family: @font-geometria-regular !important;
	@media @1366{
		font-size: 1em;
	}

	&:hover{
		box-shadow: 0 0 20px 0 @color-orange;
	}
	&:active{
		transform: scale3d(0.95, 0.95, 0.95);
	}
}

/******************************************************** Styles ********************************************************/

.header{
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: calc(~'16% + 1px');
	background-position: center;
	background-image: url(/images/pattern.jpg);
	color: white;
	z-index: 1;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
	@media @1024{
		width: calc(~'20% + 1px');
	}
	@media @900{
		bottom: auto;
		right: 0;
		width: 100%;
		padding: 1.5em 6%;
	}
	@media @600{
		padding: 1em 6%;
	}
	&__logotype{
		display: flex;
		padding: 3.125em 0;

		@media @1366{
			padding: 2.5em 0;
		}
		@media @900{
			display: inline-block;
			vertical-align: middle;
			padding: 0;
		}

		img{
			margin: auto;
			width: 10.625em;
			height: 10.625em;

			@media @1366{
				width: 7em;
				height: 7em;
			}
			@media @900{
				width: 4em;
				height: 4em;
			}
			@media @600{
				width: 3em;
				height: 3em;
			}
		}
	}
	&__button-mobile-menu{
		display: none;
		vertical-align: middle;
		height: 4em;
		width: 4em;
		position: absolute;
		right: 28.5em;
		top: 1.5em;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(/images/ic_menu.svg);
		@media @900{
			display: inline-block;
		}
		@media @600{
			height: 2.5em;
			width: 2.5em;
			left: calc(~'(100% - 2.5em) / 2');
		}
	}
	&__menu{
		@media @900{
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			background: #131313;
			background-image: none;
			text-align: center;
			height: 0;
			overflow: hidden;
			transition: all 0.3s ease;
		}
		.menu__item{
			border-top: 1px solid #010103;
			position: relative;
			font-size: 1.5em;
			font-family: @font-geometria-extraLight;
			padding: 1em 1.5em;
			transition: all 0.3s ease;
			cursor: pointer;
			z-index: 1;

			@media @1366{
				font-size: 1.3em;
			}

			&:last-child{
				border-bottom: 1px solid #010103;
				&::after{
					display: block;
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					right: 0;
					height: 1px;
					background: #2b2b2b;
					@media @900{
						display: none;
					}
				}
				@media @900{
					border-bottom: none;
				}
			}

			&::before{
				display: block;
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				height: 1px;
				background: #2b2b2b;
				z-index: 10;
			}

			&:hover{
				background: white;
				color: black;
			}
		}
	}
	&__contacts{
		text-align: center;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding-bottom: 3em;
		@media @900{
			position: static;
			padding-bottom: 0;
		}
		.contacts__phone{
			font-size: 1.5em;
			margin-bottom: 1.3em;
			font-family: @font-geometria-bold;

			@media @1366{
				font-size: 1.3em;
			}
			@media @900{
				display: inline-block;
				vertical-align: middle;
				position: absolute;
				font-size: 1em;
				top: 1.5em;
				right: 22em;
				margin-bottom: 0;
				height: 4em;
				width: 4em;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url(/images/ic_phone.svg);
			}
			@media @600{
				right: 6%;
				height: 3em;
				width: 3em;
			}

			span{
				@media @900{
					display: none;
				}
			}
		}
		.contacts__button{
			@media @900{
				position: absolute;
				top: 1.5em;
				right: 6%;
			}
			@media @600{
				display: none;
			}
		}
	}
}


.promo{
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(/images/1-img.jpg);
	padding: 9.375em 5% 15.375em 5%;
	text-align: center;
	color: white;
	position: relative;
	overflow: hidden;
	@media @600{
		padding: 5.375em 5% 13.375em 5%;
	}
	.promo__content{
		.promo__title{
			font-size: 6.6em;
			font-family: @font-georgia;
			font-weight: 600;
			transform: scale(1, 0.85);
			@media @600{
				font-size: 4em;
			}
		}
		.promo__tagline{
			margin: 1em 0 3.5em 0;
			@media @600{
				margin: 1em 0 2em 0;
			}
			.tagline__item{
				display: inline-block;
				vertical-align: middle;
				font-size: 1.45em;
				font-family: @font-geometria-light;
				line-height: 100%;
			}
			.tagline__circle{
				display: inline-block;
				vertical-align: middle;
				margin: 3px 1em 0 1em;
				border-radius: 100%;
				height: 7px;
				width: 7px;
				background: white;
			}
		}
		.promo__button{
			padding: 1.3em 4em 1.5em 4em;
		}
	}

	&::before{
		content: '';
		position: absolute;
		left: -15px;
		bottom: -40px;
		right: -15px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: bottom;
		background-image: url(/images/line.png);
		height: 80px;
	}
}


.about{
	padding: 0 14%;
	@media @1024{
		padding: 0 6%;
	}
	&__information{
		box-shadow: 0 0 50px 0 rgba(0,0,0,0.15);
		position: relative;
		top: -8em;
		background: white;
		.information__images{
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 55%;
			@media @600{
				width: 100%;
				height: 12em;
				position: static;
			}
			.image{
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				&.image-1{
					background-image: url(/images/2-img.jpg);
					height: 100%;
					display: inline-block;
					vertical-align: top;
					width: 40%;
					border: 10px solid white;
				}
				&.image-2{
					background-image: url(/images/3-img.jpg);
					height: 100%;
					display: inline-block;
					vertical-align: top;
					width: 60%;
					border: 10px solid white;
					border-left: none;
				}
			}
		}
		.information__content{
			padding: 4.35em 3em;
			width: 45%;
			margin-left: 55%;
			@media @600{
				width: 100%;
				margin-left: 0;
				padding: 2em 3em 3em 3em;
			}
			.title{
				font-size: 4.5em;
				font-family: @font-georgia;
				margin-bottom: 0.2em;
				font-weight: 600;
				transform: scale(1, 0.85);
				@media @600{
					font-size: 3em;
				}
			}
			.description{
				line-height: 150%;
				font-family: @font-geometria-regular;
			}
		}
	}

	&__statistics{
		position: relative;
		top: -4em;
		padding-bottom: 2em;
		@media @480{
			padding-bottom: 0;
		}
		.statistics__item{
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			width: 25%;
			border-right: 1px solid #d8d8d8;
			padding: 2em;
			@media @480{
				width: 50%;
			}
			&:nth-child(1){
				@media @480{
					border-bottom: 1px solid #d8d8d8;
				}

			}
			&:nth-child(2){
				@media @480{
					border-right: none;
					border-bottom: 1px solid #d8d8d8;
				}
			}
			&:nth-child(4){
				@media @480{
					border-right: none;
				}
			}
			.number{
				font-size: 2.5em;
				font-family: @font-georgia;
				margin-bottom: 0.3em;
			}
			.title{
				line-height: 150%;
			}
		}
	}
}


.menu{
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(/images/4-img.jpg);
	color: white;
	text-align: center;
	padding: 12em 5%;
	position: relative;
	overflow: hidden;
	z-index: 0;
	@media @1024{
		padding: 12em 6%;
	}
	@media @600{
		padding: 4em 6% 9em 6%;
	}

	&__content{
		width: 80%;
		margin: auto;

		@media @1024{
			width: 100%;
		}

		.menu__title{
			font-size: 4.5em;
			font-family: 'Inter', sans-serif;
			transform: scale(1, 0.85);
			margin-bottom: 70px;
			font-weight: 800;
			line-height: 102px;
			color: #000000;

			@media @600{
				font-size: 3em;
			}
		}
		.menu__description{
			padding: 0 22%;
			line-height: 160%;
			margin: 3.2em 0;
			font-family: @font-geometria-light;
			@media @1024{
				padding: 0 12%;
				margin: 2em 0 3.2em 0;
			}
			@media @600{
				padding: 0;
			}
		}
		.menu__buttons{
			.button{
				width: 18%;
				@media @1024{
					width: 22%;
				}
				@media @768{
					width: 25%;
				}
				@media @600{
					width: 51%;
				}

				&:first-child{
					margin-right: 4em;
					@media @600{
						margin-right: 0;
						margin-bottom: 2em;
					}
				}
			}
		}
	}

	&__content-header{
		width: 100%;
		display: flex;
	}

	&__content-header-border{
		display: inline-block;
		width: 50%;
		border-bottom: 5px solid white;

		&_left{
			margin-right: 2em;
		}

		&_right{
			margin-left: 2em;
		}

		@media @450{
			border-bottom: 2.5px solid white;
		}
	}

	&__content-header-title{
		display: inline-block;
		font-size: 4.5em;
		font-family: @font-georgia-bold;
		//font-weight: 600;
		transform: translateY(45%);
		line-height: 70%;
	}

	&__content-wrapper{
		display: flex;
		justify-content: space-around;
		width: 100%;
		border-left: 5px solid white;
		border-bottom: 5px solid white;
		border-right: 5px solid white;

		padding-top: 4.5em;
		padding-bottom: 2.5em;

		@media @450{
			flex-wrap: wrap;
			justify-content: center;
			border-left: 2.5px solid white;
			border-bottom: 2.5px solid white;
			border-right: 2.5px solid white;
			height: 33em;
			padding-bottom: 0;
		}
	}

	&__button{
		display: inline-block;
		width: 25%;

		@media @450{
			width: 50%;
		}
	}

	&__button-icon-substrate{
		width: 130px;
		height: 130px;
		margin: auto;

		background-color: white;
		border-radius: 0.8em;

		&:hover{
			background-color: #FBAC3C;
		}

		@media @450{
			width: 73px;
			height: 73px;
		}
	}

	&__button-icon{

		width: 100%;
		height: 100%;

		&_main{
			background-size: 51px 75px;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url(/images/main-menu.svg);

			&:hover{
				background-image: url(/images/main-menu-hover.svg);
			}

			@media @450{
				background-size: 27px 39px;
			}
		}

		&_pan-asian{
			background-size: 70px 79px;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url(/images/pan-asian-menu.svg);

			&:hover{
				background-image: url(/images/pan-asian-menu-hover.svg);
			}

			@media @450{
				background-size: 37px 42px;
			}
		}

		&_bar{
			background-size: 65px 77px;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url(/images/bar-menu.svg);

			&:hover{
				background-image: url(/images/bar-menu-hover.svg);
			}

			@media @450{
				background-size: 35px 41px;
			}
		}

		&_children{
			background-size: 86px 77px;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url(/images/children-menu.svg);

			&:hover{
				background-image: url(/images/children-menu-hover.svg);
			}

			@media @450{
				background-size: 46px 41px;
			}
		}
	}

	&__button-title{
		font-size: 2.2em;
		font-family: @font-geometria-regular;
		margin-top: 0.7em;

		@media @450{
			font-size: 1.6em;
		}
	}

	&::before{
		content: '';
		position: absolute;
		left: -35px;
		bottom: -50px;
		right: -35px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: bottom;
		background-image: url(/images/line.png);
		height: 80px;
	}
}


.bonus-card{
	padding: 4em 14% 0 14%;
	position: relative;
	z-index: 10;
	@media @1024{
		padding: 4em 6% 0 6%;
	}

	@media @600{
		padding: 4em 6%;
	}

	&__content{
		box-shadow: 0 0 50px 0 rgba(0,0,0,0.15);
		background-size: 32%;
		background-repeat: no-repeat;
		background-position: left bottom;
		background-image: url(/images/background.jpg);
		background-color: white;
		padding: 4.5em;
		position: relative;
		top: -10em;
		@media @600{
			padding: 2.5em 3em;
			top: 0;
		}
		.bonus-card__text{
			width: 55%;
			display: inline-block;
			vertical-align: middle;

			@media @1024{
				width: 100%;
			}

			.bonus-card__title{
				font-size: 4.5em;
				font-family: @font-georgia;
				line-height: 100%;
				margin-bottom: 0.4em;
				font-weight: 600;
				transform: scale(1, 0.85);
				@media @600{
					font-size: 3em;
				}
			}
			.bonus-card__description{
				line-height: 160%;
				margin-bottom: 1em;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		.bonus-card__cards{
			width: 46%;
			margin-left: 5%;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			position: absolute;
			top: 7em;
			bottom: 0;
			right: -4.5em;

			@media @1024{
				width: 100%;
				position: static;
				margin-left: 0;
				margin-top: 3em;
			}
			@media @600{
				margin-top: 1em;
			}
			.cards__image{
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url(/images/cards.png);
				height: 25em;
				@media @600{
					height: 20em;
				}
			}
			.cards__button{
				padding: 1.3em 4em 1.5em 4em;
			}
		}
	}

	&::before{
		content: '';
		position: absolute;
		left: -35px;
		bottom: -40px;
		right: -35px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: bottom;
		background-image: url(/images/line.png);
		height: 80px;
	}
}


.advantages {
	padding: 8em 5%;
	text-align: center;
	position: relative;
	@media @1024{
		padding: 8em 6%;
	}
	@media @600{
		padding: 4em 6%;
	}
	&__title{
		color: white;
		font-size: 4.5em;
		font-family: @font-georgia;
		position: relative;
		z-index: 1;
		margin-bottom: 1em;
		font-weight: 600;
		transform: scale(1, 0.85);
		@media @1024{
			margin-bottom: 1.5em;
		}
		@media @600{
			font-size: 3em;
			margin-bottom: 2.5em;
		}
	}
	&__content{
		position: relative;
		z-index: 1;
		.advantages__item{
			height: 23.125em;
			width: calc(~'(100% - 5.625em) / 4');
			display: inline-block;
			vertical-align: top;
			margin-right: 1.875em;
			background: white;
			border-radius: 10px;
			box-shadow: 0 0 50px 0 rgba(0,0,0,0.15);
			padding: 1.75em;
			overflow: hidden;
			position: relative;
			cursor: default;
			@media @1024{
				padding: 5.75em 1.75em 1.75em 1.75em;
				overflow: visible;
			}
			@media @900{
				width: calc(~'(100% - 3.75em) / 2');
				height: 19em;
			}
			@media @600{
				width: 100%;
				height: 16em;
			}
			&:last-child{
				margin-right: 0;
			}
			&:nth-child(even){
				@media @900{
				margin-right: 0;
				}
			}
			&:nth-child(1), &:nth-child(2){
				@media @900{
					margin-bottom: 6em;
				}
			}
			&:nth-child(3){
				@media @600{
					margin-bottom: 6em;
				}
			}
			.title{
				font-size: 1.6em;
				font-family: @font-georgia;
				margin-bottom: 0.75em;
				position: relative;
				z-index: 1;
				font-weight: 600;
				transform: scale(1, 0.85);
			}
			.description{
				position: absolute;
				left: 0;
				top: 7em;
				right: 0;
				padding: 0 2em;
				line-height: 160%;
				opacity: 0;
				visibility: hidden;
				z-index: 1;
				transition: all 0.3s ease;
				@media @1024{
					font-size: 0.8em;
					top: 9em;
					opacity: 1;
					visibility: visible;
					top: 12.5em;
				}
			}
			.icon{
				width: 13.5em;
				height: 13.5em;
				background: @color-orange;
				border-radius: 100%;
				margin: auto;
				position: relative;
				z-index: 0;
				transition: all 0.7s ease;

				@media @1200{
					width: 10.5em;
					height: 10.5em;
				}
				@media @1024{
					position: absolute;
					left: 0;
					top: -5em;
					right: 0;
					background: none;
					width: auto;
					text-align: center;
				}

				i{
					transition: all 0.3s ease;
					opacity: 1;
					display: block;
					position: absolute;
					left: 2.5em;
					top: 2.5em;
					right: 2.5em;
					bottom: 2.5em;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
					@media @1024{
						display: inline-block;
						width: 10.5em;
						height: 10.5em;
						background-color: @color-orange;
						margin: auto;
						border-radius: 999px;
						background-size: 60%;
						position: static;
					}
					@media @600{
						left: 1.5em;
						top: 1.5em;
						right: 1.5em;
						bottom: 1.5em;
					}
				}
			}

			&:hover{
				.icon{
					transform: translate3d(0, -210px, 0);
					@media @1366{
						transform: translate3d(0, -175px, 0)
					}
					i{
						opacity: 0;
					}
				}
				.description{
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	&__background{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		height: 70%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-image: url(/images/5-img.jpg);
		z-index: 0;
		@media @900{
			height: 80%;
		}
		@media @600{
			height: 100%;
		}
	}
}


.reservation{
	text-align: center;
	padding: 0 5% 8em 5%;
	@media @1024{
		padding: 0 6% 8em 6%;
	}
	@media @600{
		padding: 4em 6%;
	}
	&__title{
		font-size: 4.5em;
		font-family: @font-georgia;
		margin-bottom: 0.75em;
		font-weight: 600;
		transform: scale(1, 0.85);
		@media @600{
			font-size: 3em;
		}
	}
	&__content{
		.reservation__form{
			width: 58%;
			margin: auto;
			position: relative;

			@media @1200{
				width: 80%;
			}
			@media @1024{
				width: 100%;
			}

			.form__input{
				display: inline-block;
				vertical-align: top;
				width: 48%;
				text-align: left;
				margin-bottom: 4em;
				border-bottom: 1px solid #d8d8d8;
				position: relative;
				@media @600{
					width: 100% !important;
					margin-right: 0 !important;
					margin-bottom: 3em;
				}
				&.date{
					margin-right: 4%;
					width: 22%
				}
				&.time{
					margin-right: 4%;
					width: 22%
				}
				&.persons{

				}
				&.name{
					margin-right: 4%;
				}
				&.phone{

				}

				input{
					width: 100%;
					font-size: 1em;
					font-family: @font-geometria-light;
					padding: 0 0 1em 0;
					background: none;
					position: relative;
					z-index: 1;
				}
				span{
					position: absolute;
					left: 0;
					top: 0;
					z-index: 0;
					color: @color-grey;
					transition: all 0.3s ease;
					&.active{
						transform: translate3d(0,-32px,0);
					}
				}
			}
			.form__data-processing{
				margin-top: 1em;
				text-align: left;
				@media @600{
					margin-top: 0;
					margin-bottom: 2em;
				}
				input{
					background: #f7f7f7;
					appearance: none;
					height: 1.8em;
					width: 1.8em;
					display: inline-block;
					vertical-align: middle;
					border: 1px solid #ececec;
					position: relative;
					&::before{
						content: '';
						position: absolute;
						left: 7px;
						top: 7px;
						right: 7px;
						bottom: 7px;
						background: @color-orange;
						transform: scale3d(0, 0, 0);
						transition: all 0.2s ease;
						@media @600{
							left: 5px;
							top: 5px;
							right: 5px;
							bottom: 5px;
						}
					}
					&:checked{
						&::before{
							transform: scale3d(1, 1, 1);
						}
					}
				}
				span{
					color: @color-grey;
					margin-left: 1em;
					margin-top: 2px;
					display: inline-block;
					vertical-align: middle;
				}
			}
			.form__button{
				position: absolute;
				bottom: -1.5em;
				right: 0;
				padding: 1.3em 5em 1.5em 5em;
				@media @600{
					position: static;
				}
			}
		}
	}
}

.teaser {
	position: relative;
	padding: 10px 43px 40px 43px;
	border-radius: 20px;
	font-family: 'Inter', sans-serif;
	background: #FFAE02;

	@media @768 {
		padding: 5.729vw 4.688vw;
	}

	&__row {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 1;
		padding: 1.042vw 1.302vw;
		border-radius: 1.042vw;
		background: #D29106;
		
		@media (min-width: 1920px) {
			padding: 20px 25px;
			border-radius: 20px;
		}

		@media @768 {
			flex-direction: column;
			padding: 3.906vw 6.510vw;
			z-index: 2;
			border-radius: 2.604vw;
		}
	}

	&__title {
		margin-bottom: 8.594vw;
		font-size: 3.385vw;
		line-height: 5.313vw;
		color: #000;
		font-weight: 700;

		@media (min-width: 1920px) {
			margin-bottom: 165px;
			line-height: 102px;
			font-size: 65px;
		}

		@media @1024 {
			margin-bottom: 10.789vw;
		}

		@media @900 {
			font-size: 4.385vw;
			margin-bottom: 11.444vw;
		}

		@media @768 {
			font-size: 6.771vw;
			margin-bottom: 52.444vw;
		}
	}

	&__text {
		flex: 0 1 35%;
		font-weight: 700;
		font-size: 1.042vw;
		line-height: 1.354vw;
		text-align: left;
		color: #fff;

		@media (min-width: 1920px) {
			font-size: 20px;
			line-height: 26px;
		}
		@media @900 {
			font-size: 1.556vw;
			line-height: 2.111vw;
		}
		@media @768 {
			flex: 0 1 100%;
			margin-bottom: 4.688vw;
			font-size: 2.995vw;
			line-height: 3.776vw;
			text-align: center;
		}
	}
	&__button-ico {
		margin-right: 0.260vw;

		@media (min-width: 1920px) {
			margin-right: 5px;
		}
		@media @900 {
			margin-right: 0.556vw;
		}
		@media @768 {
			margin-right: 1.823vw;
		}

		& img {
			width: 1.354vw;

			@media (min-width: 1920px) {
				width: 26px;
			}
			@media @900 {
				width: 2.222vw;
			}
			@media @768 {
				width: 3.906vw;
			}
		}
	}
	&__button-text {
		color: #D29106;

		@media @768 {
			font-size: 2.344vw;
		}
	}
	&__buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex: 0 1 35%;

		@media @768 {
			flex: 0 1 100%;
			justify-content: center;
		}
	}
	&__button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-width: 7.813vw;
		height: 2.344vw;
		padding: 0.208vw 0.781vw;
		margin-right: 0.521vw;
		background: #fff;
		color: #D29106;
		border-radius: 0.521vw;
		font-weight: 600;
		cursor: pointer;
		transition: all 200ms ease;

		@media (min-width: 1920px) {
			min-width: 150px;
			height: 45px;
			padding: 4px 15px;
			margin-right: 10px;
			border-radius: 10px;
		}

		@media @900 {
			padding: 0.778vw 1.333vw;
			border-radius: 1.111vw;
			min-width: 13.889vw;
			margin-right: 1.111vw;
			height: 4.222vw;
			font-size: 1.444vw;
		}

		@media @768 {
			padding: 0.521vw 3.125vw;
			border-radius: 1.302vw;
			min-width: 24.740vw;
			margin-right: 1.953vw;
			height: 7.813vw;
		}

		&:hover {
			opacity: 0.8;
		}

		&:last-child {
			margin: 0;
		}
	}

	&__qr-link {
		position: absolute;
		bottom: 40px;
		left: 50%;
		transform: translate(-50%, 0);
		width: 20%;
		z-index: 2;

		@media (min-width: 1920px) {
			bottom: 40px;
		}

		@media @900 {
			width: 18%;
		}

		@media @768 {
			bottom: 30.208vw;
			width: 41.667vw;
			z-index: 0;
		}
	}

	&__qr {
		display: block;
		max-width: 100%;
	}
}


.contacts{
	padding: 7.2em 5%;
	position: relative;
	@media @1024{
		padding: 4em 6% 35em 6%;
	}
	&__content{
		display: inline-block;
		vertical-align: top;
		position: relative;
		z-index: 1;
		background: white;
		box-shadow: 0 0 50px 0 rgba(0,0,0,0.15);
		padding: 4.5em 5.5em;
		width: 60%;
		@media @1650{
			width: 67%;
		}
		@media @1024{
			width: 100%;
		}
		@media @900{
			padding: 3em;
		}
		.contacts__title{
			font-size: 4.5em;
			font-family: @font-georgia;
			margin-bottom: 0.25em;
			font-weight: 600;
			transform: scale(1, 0.85);
			@media @600{
				font-size: 3em;
			}
		}
		.contacts__description{
			line-height: 160%;
			margin-bottom: 2em;
			@media @600{
				margin-bottom: 1em;
			}
		}
		.contacts__item{
			margin: 0 0 2em 0;
			display: inline-block;
			vertical-align: top;
			width: 60%;
			@media @1366{
				width: 100% !important;
			}
			@media @600{
				margin: 1em 0;
			}
			&.margin-right{
				margin-right: 10%;
				// width: 30%;
			}
			.title{
				color: @color-grey;
				margin-bottom: 0.7em;
			}
			.text{
				font-size: 1.2em;
				line-height: 160%;
			}
		}
		.contacts__social{
			display: inline-block;
			vertical-align: top;
			margin-right: 12%;
			@media @1650{
				margin-right: 8%;
			}
			@media @600{
				margin-right: 0;
				margin-bottom: 2em;
				width: 100%;
			}
			.social__item{
				display: inline-block;
				vertical-align: middle;
				height: 4.2em;
				width: 4.2em;
				background-size: 50%;
				background-repeat: no-repeat;
				background-position: center;
				border-radius: 100%;
				border: 3px solid @color-orange;
				margin-right: 0.8em;
				cursor: pointer;
				transition: all 0.3s ease;
				&.in{
					background-image: url(/images/insta.svg);
					&:hover{
						background-image: url(/images/insta-white.svg);
					}
				}
				&.vk{
					background-image: url(/images/vk.svg);
					&:hover{
						background-image: url(/images/vk-white.svg);
					}
				}
				&.trip{
					background-image: url(/images/tripadvisor.svg);
					&:hover{
						background-image: url(/images/tripadvisor-white.svg);
					}
				}
				&:hover{
					background-color: @color-orange;
				}
			}
		}
		.contacts__button{
			padding: 1.3em 4em 1.5em 4em;
			color: white !important;
			@media @1366{
				padding: 1.3em 2.5em 1.5em 2.5em;
			}
			@media @1200{
				margin-top: 2em;
			}
		}
	}
	&__map{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
	}
}

.modal-window{
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	height: 100%;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s ease;
	padding: 3em;
	&.open{
		opacity: 1;
		visibility: visible;
	}

	&__content{
		padding: 3em 0;
		position: relative;
		z-index: 1;
		position: absolute;
		left: 30%;
		top: 0;
		right: 30%;
		bottom: 0;
		overflow-x: hidden;

		.holder{
			position: absolute;
			left: 0;
			top: 0;
			right: -17px;
			bottom: 0;
			padding-right: 17px;
			overflow-y: scroll;
			@media @768{
				padding-right: 0;
				right: 0;
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;
			}
		}

		@media @1024{
			left: 25%;
			right: 25%;
		}
		@media @768{
			left: 20%;
			right: 20%;
			-webkit-overflow-scrolling: touch;
		}
		@media @768{
			left: 0;
			right: 0;
			padding: 0;
		}

		&.element::-webkit-scrollbar { width: 0; }
		&.element { -ms-overflow-style: none; }
		&.element { overflow: -moz-scrollbars-none; }

		.modal-window__body{
			background: white;
			padding: 3em 5em;
			position: relative;
			@media @600{
				padding: 4em 3em;
			}
			.modal-window__button-close{
				position: absolute;
				top: 1.5em;
				right: 1.5em;
				height: 2em;
				width: 2em;
				background-image: url(/images/close.svg);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				cursor: pointer;

			}
			.modal-window__logotype{
				height: 10em;
				height: 10em;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				background-image: url(/images/logotype-black.png);
				margin-bottom: 1.5em;
			}
			.modal-window__title{
				font-size: 1.6em;
				text-align: center;
				font-family: @font-geometria-bold;
				text-transform: uppercase;
				margin-bottom: 1em;
			}
			.modal-window__form{
				text-align: center;
				.modal-window__input{
					position: relative;
					margin-bottom: 2em;
					text-align: left;
					input{
						width: 100%;
						padding: 1.1em 0;
						font-family: @font-geometria-regular;
						font-size: 1.2em;
						border-bottom: 3px solid #e9e9e9;
						transition: all 0.2s ease;
						&.focus{
							border-bottom: 3px solid @color-grey;
						}
						&.orange{
							border-bottom: 3px solid @color-orange !important;
						}
					}
					.line{
						position: absolute;
						left: 0;
						bottom: 0;
						right: 0;
						height: 3px;
					}
				}
				.modal-window__ckeckbox{
					margin-bottom: 2em;
					text-align: left;
					p{
						display: inline-block;
						vertical-align: middle;
						font-size: 1.2em;
					}
					& > p{
						width: 45%;
					}
					input{
						background: #f7f7f7;
						appearance: none;
						height: 1.8em;
						width: 1.8em;
						display: inline-block;
						vertical-align: middle;
						border: 1px solid #ececec;
						position: relative;
						&::before{
							content: '';
							position: absolute;
							left: 7px;
							top: 7px;
							right: 7px;
							bottom: 7px;
							background: @color-orange;
							transform: scale3d(0, 0, 0);
							transition: all 0.2s ease;
							@media @600{
								left: 5px;
								top: 5px;
								right: 5px;
								bottom: 5px;
							}
						}
						&:checked{
							&::before{
								transform: scale3d(1, 1, 1);
							}
						}
					}
					.ckeckbox__item{
						display: inline-block;
						vertical-align: middle;
						margin-left: 1em;
					}
				}
				.data-processing{
					&.ckeckbox__item{
						margin-left: 0;
					}
					position: relative;
					input{
						position: absolute;
						left: 0;
						top: 0;
						left: 0;
					}
					p{
						margin-left: 2em;
					}
				}
				.modal-window__button{
					padding: 1.3em 4em 1.5em 4em;
				}
			}
		}
	}
	&__blackout{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		background: rgba(0,0,0,0.8);
	}
}

// smartbanner
#smartbanner {
	position: relative !important;
	z-index: 99999 !important;
	top: 0 !important;
	position: absolute !important;
	left:0;
	top:-82px;
	border-bottom: 1px solid #e8e8e8;
	width:100%; height:78px;
	font-family:'Helvetica Neue',sans-serif;
	background: -webkit-linear-gradient(top, #f4f4f4 0%,#cdcdcd 100%);
	background-image: -ms-linear-gradient(top, #F4F4F4 0%, #CDCDCD 100%);
	background-image: -moz-linear-gradient(top, #F4F4F4 0%, #CDCDCD 100%);
	box-shadow: 0 1px 2px rgba(0,0,0,0.5);
	z-index: 9998;
	-webkit-font-smoothing: antialiased;
	overflow: hidden;
	-webkit-text-size-adjust:none;
	user-select: none;

	&.no-icon{
		.sb-icon {
			display: none;
		}
	}

	&.windows .sb-icon {
		border-radius: 0;
	}

	&.android{
		border-color:#212228;
		background: #3d3d3d url('data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7');
		border-top: 5px solid #88B131;
		box-shadow: none;

		.sb-close {
			border: 0;
			width: 17px;
			height: 17px;
			line-height: 17px;
			color: #b1b1b3;
			background: #1c1e21;
			text-shadow: 0 1px 1px #000;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3);

			&:active {
				color: #eee;
			}
		}

		.sb-info {
			color: #ccc;
			text-shadow: 0 1px 2px #000;

			strong {
				color: #fff;
			}
		}

		.sb-button {
			min-width: 12%;
			border: none;
			padding: 1px;
			color: #d1d1d1;
			background: none;
			border-radius: 0;
			box-shadow: none;
			min-height: 28px;

			span {
				text-align: center;
				display: block;
				padding: 5px 10px;
				background-color: #007930;
				background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#007930), to(#007930));
				background-image: -moz-linear-gradient(top, #007930, #007930);
				text-transform: none;
				text-shadow: none;
				box-shadow: none;
			}

			&:active, &:hover {
				background: none;

				span {
					background: #2AC7E1;
				}
			}
		}
	}

	.sb-container {
		margin: 0 auto;
	}

	.sb-close {
		position: absolute;
		left: 5px;
		top: 5px;
		display: block;
		width: 14px;
		height: 14px;
		font-family: 'ArialRoundedMTBold', Arial;
		font-size: 15px;
		line-height: 15px;
		text-align: center;
		color: #fff;
		background: #070707;
		text-decoration: none;
		text-shadow: none;
		border-radius: 14px;
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
		-webkit-font-smoothing: subpixel-antialiased;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 6px 6px;
		background-image: url(../images/close-white.svg);

		&:active {
			font-size: 13px;
			color: #aaa;
		}
	}

	.sb-icon {
		position: absolute;
		left: 30px;
		top: 10px;
		display: block;
		width: 57px;
		height: 57px;
		background: rgba(0, 0, 0, 0.6);
		background-size: cover;
		border-radius: 10px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

		&.gloss{
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: -1px;
				border-top: 1px solid rgba(255, 255, 255, 0.8);
				width: 100%;
				height: 50%;
				background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.2) 100%);
				border-radius: 10px 10px 12px 12px;
			}
		}
	}

	.sb-info {
		position: absolute;
		left: 98px;
		top: 18px;
		width: 44%;
		font-size: 11px;
		line-height: 1.2em;
		font-weight: bold;
		color: #6a6a6a;
		text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);

		strong {
			display: block;
			font-size: 13px;
			color: #4d4d4d;
			line-height: 18px;
		}

		& > span {
			display: block;
		}

		em {
			font-style: normal;
			text-transform: uppercase;
		}
	}

	.sb-button {
		position: absolute;
		right: 20px;
		top: 24px;
		border: 1px solid #bfbfbf;
		padding: 0 10px;
		min-width: 10%;
		height: 24px;
		font-size: 14px;
		line-height: 24px;
		text-align: center;
		font-weight: bold;
		color: #6a6a6a;
		background: -webkit-linear-gradient(top, #efefef 0%, #dcdcdc 100%);
		text-transform: uppercase;
		text-decoration: none;
		text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
		border-radius: 3px;
		box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6), 0 1px 0 rgba(255, 255, 255, 0.7) inset;

		&:hover, &:active{
			background: -webkit-linear-gradient(top, #dcdcdc 0%, #efefef 100%)
		}
	}
}

#smartbanner, html.sb-animation {
	-webkit-transition: all .3s ease;
}

#smartbanner #smartbanner.no-icon .sb-info {
	left: 34px;
}

.smartbanner_android, .smartbanner_ios{
	display: none;
}